/* P r o p r i e t a r y N o t i c e */
/* Unpublished © 2020 Allscripts Healthcare, LLC and/or its affiliates. All Rights
Reserved.
*
* P r o p r i e t a r y N o t i c e: This software has been provided pursuant to a License Agreement, with Allscripts
Healthcare, LLC and/or its affiliates, containing restrictions on its use. This software contains valuable trade secrets
and proprietary information of Allscripts Healthcare, LLC and/or its affiliates and is protected by trade secret and
copyright law. This software may not be copied or distributed in any form or medium, disclosed to any third parties,
or used in any manner not provided for in said License Agreement except with prior written authorization from
Allscripts Healthcare, LLC and/or its affiliates. Notice to U.S. Government Users: This software is “Commercial
Computer Software.”
Allscripts Common Services Operations Portal is a trademark of Allscripts Healthcare, LLC and/or its affiliates.
*
*
*/
/* P r o p r i e t a r y N o t i c e */

import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { StorageService } from 'src/app/core/services/storage.service';
import {
  CoreConstants
} from 'src/app/core/constants';

import { Constants } from '../../types/constants';
import { AzureAdService } from '../azureservices/azure-ad-service';
import { ConfigService } from '../configservice/configuration.service';
import { GenericDataService } from '../dataservice/generic-data.service.';
import {
  DataRequest,
  HttpActionType
} from '../../../../../../projects/csop-lib/src/public-api';

@Injectable({
  providedIn: 'root'
})

export class CsopSignalRService {
  private connectionStarted = false;
  // tslint:disable-next-line:no-uninitialized
  private hubConnection!: signalR.HubConnection;

  public constructor(
    private readonly azureAdService: AzureAdService,
    private readonly configService: ConfigService,
    private readonly genericDataService: GenericDataService,
    private readonly storage: StorageService) {
  }

  public listenerMethod = () => {
    this.hubConnection.on('LogoutMessage', () => {
      this.azureAdService.sendWarningMessage();
      const timeout = 10000;
      setInterval(() => { this.azureAdService.logout(); }, timeout);
    });
  }

  public saveConnectionData(id: string) {
    const email = this.storage.getItem(CoreConstants.email);
    return this.genericDataService.execute(
      new DataRequest(
        HttpActionType.GET,
        Constants.signalR,
        Constants.connection,
        id,
        email.toLowerCase()
      ));
  }

  public async saveConnectionDetails(id: string) {
    // tslint:disable-next-line:no-any
    await this.saveConnectionData(id).toPromise().catch((err: any) => {
      console.error(err);
    });
  }

  public startConnection() {
    if (!this.connectionStarted) {
      const url = this.configService.config.csopsettings.redirectUri;
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(url + Constants.hub)
        .configureLogging(signalR.LogLevel.Error)
        .build();

      // tslint:disable-next-line:no-any
      this.hubConnection.start().catch(err => console.error(err.toString())).then(() => {
        console.log('Connection is established');
        this.listenerMethod();
        this.hubConnection.invoke('GetConnectionID')
          // tslint:disable-next-line:no-any
          .then((data: any) => {
            this.saveConnectionDetails(data);
          });
      });
      this.connectionStarted = true;
    }
  }
}
