import * as i0 from "./app-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./app-selector.component";
import * as i4 from "@angular/router";
import * as i5 from "../core/services/storage.service";
import * as i6 from "../../../projects/csop-lib/src/lib/services/configservice/configuration.service";
var styles_AppSelectorComponent = [i0.styles];
var RenderType_AppSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppSelectorComponent, data: {} });
export { RenderType_AppSelectorComponent as RenderType_AppSelectorComponent };
function View_AppSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "card ml-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAppSelected(_v.context.$implicit.appName) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "backgroundColor": 0 }), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit.appColor); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.appTitle; _ck(_v, 4, 0, currVal_1); }); }
export function View_AppSelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "mainDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "col-12 d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [["class", "list-group list-group-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppSelectorComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.application; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_AppSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-selector-cmp", [], null, null, null, View_AppSelectorComponent_0, RenderType_AppSelectorComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppSelectorComponent, [i4.ActivatedRoute, i4.Router, i5.StorageService, i6.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppSelectorComponentNgFactory = i1.ɵccf("app-selector-cmp", i3.AppSelectorComponent, View_AppSelectorComponent_Host_0, {}, {}, []);
export { AppSelectorComponentNgFactory as AppSelectorComponentNgFactory };
