import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OnDestroy, OnInit, AfterViewInit, } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationStart, } from '@angular/router';
import { fromEvent, } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { CsopSignalRService } from 'projects/csop-lib/src/lib/services/signalRservice/csop-signalr.service';
import { AuthState } from '../core/stores/Auth/auth.state';
import { UserAccessProfile, } from '../core/model';
import { CoreConstants, Applications, } from '../core/constants';
import { StoreUserProfile } from '../core/stores/Auth/store-userprofile.action';
import { ProfileRoleHelper, SharedFunctions } from '../core/helpers';
import { StateService } from '../state.service';
import { StorageService } from '../core/services/storage.service';
import { AlertMessageType } from '../../../projects/csop-lib/src/lib/types/enum/alert-message-type.enum';
import { ConfigService } from '../../../projects/csop-lib/src/lib/services/configservice/configuration.service';
import { LoginService } from '../../../projects/csop-lib/src/lib/services/loginservices/login.service';
import { ToasterService } from '../../../projects/csop-lib/src/lib/services/toastrservice/toastr.service';
import { AzureAdService } from '../../../projects/csop-lib/src/lib/services/azureservices/azure-ad-service';
import { AuthenticationType, CsopAlertMessageType, CsopAlertService, CsopEventType, CsopDialogService, CsopSwapEventType, Constants, CsopProgressBarService, } from '../../../projects/csop-lib/src/public-api';
import { Logout } from '../core/stores/Auth/auth.logout.action';
var CoreAppContainerComponent = (function () {
    function CoreAppContainerComponent(alertService, azureAdService, configService, dialogService, location, loginService, profileRoleHelper, router, stateSvc, store, storage, toastr, signalrService, progressBarService, http) {
        var _this = this;
        this.alertService = alertService;
        this.azureAdService = azureAdService;
        this.configService = configService;
        this.dialogService = dialogService;
        this.location = location;
        this.loginService = loginService;
        this.profileRoleHelper = profileRoleHelper;
        this.router = router;
        this.stateSvc = stateSvc;
        this.store = store;
        this.storage = storage;
        this.toastr = toastr;
        this.signalrService = signalrService;
        this.progressBarService = progressBarService;
        this.http = http;
        this.alertComponentId = 'csop-app-container-alert';
        this.application = [];
        this.progressBarId = 'csop-app-container-progressbar';
        this.swappedApp = '';
        this.userName = '';
        this.targetedUrlIsAzureAd = false;
        this.appRequest = fromEvent(document, CsopEventType[CsopEventType.Request]);
        this.appNotification = fromEvent(document, CsopEventType[CsopEventType.Notify]);
        this.userProfile = new UserAccessProfile('', []);
        this.defaultApp = CoreConstants.empty;
        if (!this.storage.getKey('ukey')) {
            this.storage.storeKey(JSON.stringify(SharedFunctions.makeRandomString(CoreConstants.keyLength)));
        }
        this.application = this.configService.config.csopsettings.applications;
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                _this.targetedUrlIsAzureAd = event.url.includes('azureAd') ? true : false;
            }
        });
    }
    CoreAppContainerComponent.prototype.logout = function () {
        this.removeContent();
        if (this.azureAdService.isUserAuthenticated()) {
            if (this.selectedApplication !== undefined) {
                this.azureAdService.selectedApplication = this.selectedApplication.appName;
            }
            this.targetedUrlIsAzureAd = true;
            this.store.dispatch(new Logout());
        }
        else {
            this.storage.clear();
            this.loginService.redirectToAppLauncher();
        }
    };
    CoreAppContainerComponent.prototype.ngAfterViewInit = function () {
        this.progressBarService.showHideProgressBar(true, this.progressBarId);
    };
    CoreAppContainerComponent.prototype.ngOnDestroy = function () {
        if (this.appNotificationSubscription !== undefined) {
            this.appRequestSubscription.unsubscribe();
        }
        if (this.appRequestSubscription !== undefined) {
            this.appRequestSubscription.unsubscribe();
        }
    };
    CoreAppContainerComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var intialSelectedApp, delay;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.targetedUrlIsAzureAd = false;
                this.defaultApp = this.storage.getItem(Constants.app);
                intialSelectedApp = this.application.find(function (app) { return app.appName === _this.defaultApp; });
                if (this.storage.getItem('sessionStatus') !== 'true') {
                    this.http.get('SessionStart', {
                        headers: new HttpHeaders({
                            'Content-Type': 'application/json'
                        })
                    }).subscribe();
                    this.storage.setItem('sessionStatus', 'true');
                    delay = 100;
                    SharedFunctions.sleep(delay);
                }
                if (intialSelectedApp !== undefined) {
                    if (intialSelectedApp.authenticationMechanism.name === AuthenticationType.AzureAd) {
                        if (this.azureAdService.isUserAuthenticated()) {
                            if (this.storage.getItem(CoreConstants.isUserLoggedIn) === Constants.empty) {
                                this.storage.setItem(CoreConstants.isUserLoggedIn, 'true');
                                this.signalrService.startConnection();
                            }
                            this.azureAdService.getIdToken()
                                .then(function (idToken) {
                                if (idToken !== undefined) {
                                    _this.storage.setItem('app', Applications.CareQuality);
                                    var userAccessProfile = _this.profileRoleHelper.getUserAccessProfile(idToken);
                                    _this.userName = userAccessProfile.userName;
                                    if (userAccessProfile.appProfiles.length === 0) {
                                        SharedFunctions.sleep(CoreConstants.sleepTimeMs);
                                        userAccessProfile = _this.profileRoleHelper.getUserAccessProfile(idToken);
                                    }
                                    if (!_this.loginService.verifyEmail(userAccessProfile.userName)) {
                                        _this.logout();
                                        return;
                                    }
                                    if (userAccessProfile.appProfiles.length === 0) {
                                        _this.showMessage(CsopAlertMessageType.Danger, CoreConstants.notAuthorizedMsg);
                                    }
                                    else {
                                        _this.store.dispatch(new StoreUserProfile(userAccessProfile))
                                            .subscribe(function (dataProfile) {
                                            if (dataProfile !== undefined &&
                                                dataProfile.auth !== undefined) {
                                                _this.userProfile = dataProfile.auth;
                                                _this.setSelectedApplication(Applications.CareQuality);
                                                _this.appNotificationSubscription = _this.appNotification.subscribe(function (data) {
                                                    var coreEvent = data.detail;
                                                    _this.toastr.info(coreEvent.payload, 'App ID::' + coreEvent.elementId);
                                                });
                                                _this.appRequestSubscription = _this.appRequest.subscribe(function (data) {
                                                    var coreEvent = data.detail;
                                                    if (_this.user$ !== undefined) {
                                                        _this.user$.subscribe(function (user) {
                                                            var docEvent = new CustomEvent(CsopEventType[CsopEventType.Response] + '_' + coreEvent.elementId, { detail: user.token });
                                                            document.dispatchEvent(docEvent);
                                                        });
                                                    }
                                                });
                                            }
                                            else {
                                                _this.toastr.warning('You are not authorized to access any application.');
                                            }
                                        }, function (error) {
                                            console.error(error);
                                        });
                                    }
                                }
                            }, function (error) {
                                _this.toastr.error('Failed to load Id token.');
                                console.error(error);
                            });
                        }
                    }
                    else {
                        if (this.loginService.isUserAuthenticated) {
                            this.userName = this.storage.getItem('fullName');
                            if (this.storage.getItem(CoreConstants.isUserLoggedIn) === Constants.empty) {
                                this.storage.setItem(CoreConstants.isUserLoggedIn, 'true');
                                this.signalrService.startConnection();
                            }
                            this.userProfile.appProfiles = this.profileRoleHelper.getApplicationProfiles();
                            this.setSelectedApplication(this.storage.getItem('app'));
                        }
                        else {
                            this.toastr.warning('User is not authorized to access any application.');
                        }
                    }
                }
                return [2];
            });
        });
    };
    CoreAppContainerComponent.prototype.onAppChange = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var inputElement, appLabel, result, targetedApp_1, targetedAppSettings;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        inputElement = event.target;
                        appLabel = inputElement.selectedOptions[0].label;
                        return [4, this.dialogService.openConfirmDialog(CoreConstants.dlgTitleConfirmation, 'Please save your work before navigating to the ' + appLabel, CoreConstants.dlgBtnSaveContinue, CoreConstants.dlgBtnCancel)
                                .catch(function (err) {
                                console.error(err);
                            })];
                    case 1:
                        result = _a.sent();
                        if (result === true) {
                            targetedApp_1 = inputElement.selectedOptions[0].value;
                            targetedAppSettings = this.getAppSetting(targetedApp_1);
                            if (targetedAppSettings !== undefined) {
                                this.storage.setItem(Constants.app, targetedApp_1);
                                if (targetedAppSettings.authenticationMechanism.name === AuthenticationType.AzureAd) {
                                    if (this.azureAdService.isUserAuthenticated()) {
                                        this.swappedApp = targetedApp_1;
                                        this.setSelectedApplication(targetedApp_1);
                                    }
                                    else {
                                        this.storage.setItem(CoreConstants.isUserLoggedIn, Constants.empty);
                                        this.router.navigate(['/azureAd'], { queryParams: { app: targetedApp_1 } });
                                    }
                                }
                                else if (targetedAppSettings.authenticationMechanism.name === AuthenticationType.Shield) {
                                    this.isAuthorized(targetedAppSettings).then(function (response) {
                                        if (response === true) {
                                            _this.storage.setItem(Constants.app, _this.defaultApp);
                                            _this.swappedApp = targetedApp_1;
                                            if (_this.storage.getItem('app') === Applications.FoD) {
                                                _this.sendSwapEvent();
                                            }
                                            else {
                                                _this.setSelectedApplication(targetedApp_1);
                                            }
                                        }
                                        else {
                                            _this.storage.setItem(CoreConstants.isUserLoggedIn, Constants.empty);
                                            _this.router.navigate(['/login'], { queryParams: { app: targetedApp_1 } });
                                        }
                                    }).catch(function (error) {
                                        console.log(error);
                                        _this.showToasterMessage(AlertMessageType.Danger, 'You are not authorized to access ' + appLabel + '.');
                                    });
                                }
                            }
                        }
                        else {
                            inputElement.value = this.storage.getItem('app');
                        }
                        return [2];
                }
            });
        });
    };
    CoreAppContainerComponent.prototype.windowCloseEvent = function () {
        if (!this.targetedUrlIsAzureAd) {
            this.logout();
        }
    };
    CoreAppContainerComponent.prototype.getAppSetting = function (appName) {
        var appSetting = this.application.find(function (app) { return app.appName === appName; });
        return appSetting !== undefined ? appSetting : undefined;
    };
    CoreAppContainerComponent.prototype.getShieldAuthenticated = function (appSettings) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var token, anotherShieldApp, tokenKey, _a;
                        return tslib_1.__generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    token = null;
                                    anotherShieldApp = this.application.find(function (app) {
                                        return app.authenticationMechanism.name === AuthenticationType.Shield
                                            && app.appName !== appSettings.appName;
                                    });
                                    if (!(anotherShieldApp !== undefined)) return [3, 4];
                                    tokenKey = anotherShieldApp.appName + '-' + anotherShieldApp.authenticationMechanism.setting.tokenType;
                                    token = this.storage.getItem(tokenKey);
                                    if (!(token !== null && token !== undefined && token !== '')) return [3, 1];
                                    resolve(true);
                                    return [3, 3];
                                case 1:
                                    _a = resolve;
                                    return [4, this.loginService.getRefreshTokenPromise(appSettings.appName, anotherShieldApp.appName)];
                                case 2:
                                    _a.apply(void 0, [_b.sent()]);
                                    _b.label = 3;
                                case 3: return [3, 5];
                                case 4:
                                    resolve(false);
                                    _b.label = 5;
                                case 5: return [2];
                            }
                        });
                    }); })];
            });
        });
    };
    CoreAppContainerComponent.prototype.handleMessage = function (msg) {
        this.toastr.info('shell received message: ', msg.detail);
    };
    CoreAppContainerComponent.prototype.handleSessionTimeout = function () {
        var _this = this;
        if (this.activeToast === undefined ||
            (this.activeToast !== undefined &&
                this.activeToast.message !== 'Session Timed out!')) {
            this.activeToast = this.toastr.error('Session Timed out!');
            var delay = 2000;
            setTimeout(function () {
                _this.logout();
            }, delay);
        }
    };
    CoreAppContainerComponent.prototype.handleSwapping = function (swapEvent) {
        switch (swapEvent) {
            case CsopSwapEventType.Continue:
                this.setSelectedApplication(this.swappedApp);
                break;
            case CsopSwapEventType.Cancel:
                this.defaultApp = this.storage.getItem(Constants.app);
        }
    };
    CoreAppContainerComponent.prototype.isAuthorized = function (appSettings) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var token;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    token = null;
                                    if (!(appSettings !== undefined)) return [3, 4];
                                    token = this.storage.getItem(appSettings.appName + '-' + appSettings.authenticationMechanism.setting.tokenType);
                                    if (!(token === null || token === undefined || token === '')) return [3, 2];
                                    return [4, this.getShieldAuthenticated(appSettings)
                                            .catch(function (err) {
                                            console.error(err);
                                            resolve(false);
                                        })
                                            .then(function (res) {
                                            res === true ? resolve(true) : resolve(false);
                                        })];
                                case 1:
                                    _a.sent();
                                    return [3, 3];
                                case 2:
                                    resolve(true);
                                    _a.label = 3;
                                case 3: return [3, 5];
                                case 4:
                                    resolve(false);
                                    _a.label = 5;
                                case 5: return [2];
                            }
                        });
                    }); })];
            });
        });
    };
    CoreAppContainerComponent.prototype.loadApplication = function (app) {
        var _this = this;
        var content = document.getElementById('content');
        if (content === null || content === undefined) {
            SharedFunctions.sleep(CoreConstants.sleepTimeMs);
            content = document.getElementById('content');
        }
        if (content !== null) {
            var script = document.createElement('script');
            script.src = app.appModulePath;
            content.appendChild(script);
            var element = document.createElement(app.appName);
            content.appendChild(element);
            element.addEventListener('message', function (msg) { return _this.handleMessage(msg); });
            element.addEventListener('session timeout', function () { return _this.handleSessionTimeout(); });
            content.addEventListener(CsopSwapEventType[CsopSwapEventType.Continue], function () { return _this.handleSwapping(CsopSwapEventType.Continue); });
            content.addEventListener(CsopSwapEventType[CsopSwapEventType.Cancel], function () { return _this.handleSwapping(CsopSwapEventType.Cancel); });
            element.setAttribute('clientid', app.appName);
            element.setAttribute('state', JSON.stringify(this.userProfile.appProfiles.find(function (t) { return t.appName === app.appName; })));
            script.onerror = function () { return console.error("error loading " + app.appName); };
            this.stateSvc.registerClient(element);
        }
    };
    CoreAppContainerComponent.prototype.removeContent = function () {
        var content = document.getElementById('content');
        if (content !== null) {
            while (content.firstChild !== null) {
                content.removeChild(content.firstChild);
            }
        }
    };
    CoreAppContainerComponent.prototype.sendSwapEvent = function () {
        var swapEvent = new Event(CsopSwapEventType[CsopSwapEventType.Notify], { bubbles: false, cancelable: false });
        var app = this.storage.getItem('app');
        var appElement = document.getElementsByTagName(app)[0];
        appElement.dispatchEvent(swapEvent);
    };
    CoreAppContainerComponent.prototype.setSelectedApplication = function (app) {
        var _this = this;
        var listAppProfiles = this.userProfile.appProfiles;
        if (listAppProfiles.length > 0) {
            this.selectedApplication = listAppProfiles.find(function (X) { return X.appName.toLowerCase() === app.toLowerCase(); });
            if (this.selectedApplication !== undefined) {
                this.removeContent();
                this.defaultApp = this.selectedApplication.appName;
                this.loadApplication(this.selectedApplication);
                this.storage.setItem('app', this.selectedApplication.appName);
                var selectedAppDom = document.querySelector(this.selectedApplication.appName);
                var config = { childList: true };
                var observer_1 = new MutationObserver(function () {
                    _this.progressBarService.showHideProgressBar(false, _this.progressBarId);
                    observer_1.disconnect();
                });
                observer_1.observe(selectedAppDom, config);
                this.location.go(this.location.normalize(this.selectedApplication.defaultNavigationPath));
            }
            else {
                this.router.navigate(['/login'], { queryParams: { app: app } });
                return;
            }
        }
    };
    CoreAppContainerComponent.prototype.showMessage = function (type, message) {
        var alert = {
            type: type,
            message: message,
            alertComponentId: this.alertComponentId
        };
        this.alertService.open(alert);
    };
    CoreAppContainerComponent.prototype.showToasterMessage = function (type, message) {
        if (this.activeToast === undefined ||
            (this.activeToast !== undefined &&
                (this.activeToast.message !== message ||
                    this.activeToast.toastRef.isInactive()))) {
            switch (type) {
                case AlertMessageType.Danger:
                    this.activeToast = this.toastr.error(message);
                    break;
                case AlertMessageType.Info:
                    this.activeToast = this.toastr.info(message);
                    break;
                case AlertMessageType.Warning:
                    this.activeToast = this.toastr.warning(message);
                    break;
                case AlertMessageType.Success:
                    this.activeToast = this.toastr.success(message);
                    break;
            }
        }
    };
    tslib_1.__decorate([
        Select(AuthState),
        tslib_1.__metadata("design:type", Object)
    ], CoreAppContainerComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        Select(AuthState),
        tslib_1.__metadata("design:type", Object)
    ], CoreAppContainerComponent.prototype, "userAccessProfile$", void 0);
    return CoreAppContainerComponent;
}());
export { CoreAppContainerComponent };
