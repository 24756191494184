/* P r o p r i e t a r y N o t i c e */
/* Unpublished © 2020 Allscripts Healthcare, LLC and/or its affiliates. All Rights
Reserved.
*
* P r o p r i e t a r y N o t i c e: This software has been provided pursuant to a License Agreement, with Allscripts
Healthcare, LLC and/or its affiliates, containing restrictions on its use. This software contains valuable trade secrets
and proprietary information of Allscripts Healthcare, LLC and/or its affiliates and is protected by trade secret and
copyright law. This software may not be copied or distributed in any form or medium, disclosed to any third parties,
or used in any manner not provided for in said License Agreement except with prior written authorization from
Allscripts Healthcare, LLC and/or its affiliates. Notice to U.S. Government Users: This software is “Commercial
Computer Software.”
Allscripts Common Services Operations Portal™ is a trademark of Allscripts Healthcare, LLC and/or its affiliates.
*
*
*/
/* P r o p r i e t a r y N o t i c e */
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy
} from '@angular/common';
import {
  Router,
  NavigationStart,
} from '@angular/router';
import {
  fromEvent,
  Observable,
  Subscription,
} from 'rxjs';
import {
  Store,
  Select
} from '@ngxs/store';
import { ActiveToast } from 'ngx-toastr';
import { CsopSignalRService } from 'projects/csop-lib/src/lib/services/signalRservice/csop-signalr.service';

import { AuthState } from '../core/stores/Auth/auth.state';
import {
  AppProfile,
  AuthStateModel,
  UserAccessProfile,
} from '../core/model';
import {
  CoreConstants,
  Applications,
} from '../core/constants';
import { StoreUserProfile } from '../core/stores/Auth/store-userprofile.action';
import {
  ProfileRoleHelper,
  SharedFunctions
} from '../core/helpers';
import { StateService } from '../state.service';
import { StorageService } from '../core/services/storage.service';
import { AlertMessageType } from '../../../projects/csop-lib/src/lib/types/enum/alert-message-type.enum';
import { ConfigService } from '../../../projects/csop-lib/src/lib/services/configservice/configuration.service';
import { LoginService } from '../../../projects/csop-lib/src/lib/services/loginservices/login.service';
import { ToasterService } from '../../../projects/csop-lib/src/lib/services/toastrservice/toastr.service';
import { AzureAdService } from '../../../projects/csop-lib/src/lib/services/azureservices/azure-ad-service';
import {
  IAppSettings,
  AuthenticationType,
  CsopAlertMessageType,
  CsopAlertService,
  CoreEvent,
  CsopEventType,
  ICsopAlertMessage,
  CsopDialogService,
  CsopSwapEventType,
  Constants,
  CsopProgressBarService,
} from '../../../projects/csop-lib/src/public-api';
import { Logout } from '../core/stores/Auth/auth.logout.action';

@Component({
  selector: 'app-app-container',
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy }],
  templateUrl: './core-app-container.component.html',
  styleUrls: ['./core-app-container.component.scss']
})

export class CoreAppContainerComponent implements OnInit, OnDestroy, AfterViewInit {
  public readonly alertComponentId = 'csop-app-container-alert';
  public application: Array<IAppSettings> = [];
  public defaultApp: string;
  public progressBarId = 'csop-app-container-progressbar';
  public swappedApp = '';
  @Select(AuthState) public user$: Observable<AuthStateModel> | undefined;
  @Select(AuthState) public userAccessProfile$: Observable<Array<UserAccessProfile>> | undefined;
  public userName = '';
  public userProfile: UserAccessProfile;
  private activeToast!: ActiveToast<any> | undefined; // tslint:disable-line:no-any
  private readonly appNotification: Observable<Event>;
  // tslint:disable-next-line:no-uninitialized
  private appNotificationSubscription!: Subscription;
  private readonly appRequest: Observable<Event>;
  // tslint:disable-next-line:no-uninitialized
  private appRequestSubscription!: Subscription;
  private selectedApplication!: AppProfile | undefined;
  private targetedUrlIsAzureAd = false;
  public constructor(
    private readonly alertService: CsopAlertService,
    private readonly azureAdService: AzureAdService,
    private readonly configService: ConfigService,
    private readonly dialogService: CsopDialogService,
    private readonly location: Location,
    private readonly loginService: LoginService,
    private readonly profileRoleHelper: ProfileRoleHelper,
    private readonly router: Router,
    private readonly stateSvc: StateService,
    private readonly store: Store,
    private readonly storage: StorageService,
    private readonly toastr: ToasterService,
    private readonly signalrService: CsopSignalRService,
    private readonly progressBarService: CsopProgressBarService,
    protected http: HttpClient,
  ) {
    this.appRequest = fromEvent(document, CsopEventType[CsopEventType.Request]);
    this.appNotification = fromEvent(document, CsopEventType[CsopEventType.Notify]);
    this.userProfile = new UserAccessProfile('', []);
    this.defaultApp = CoreConstants.empty;
    if (!this.storage.getKey('ukey')) { // tslint:disable-line:strict-boolean-expressions
      this.storage.storeKey(JSON.stringify(SharedFunctions.makeRandomString(CoreConstants.keyLength)));
    }
    this.application = this.configService.config.csopsettings.applications;

    this.router.events.subscribe((event: any) => { // tslint:disable-line:no-any
      if (event instanceof NavigationStart) {
        this.targetedUrlIsAzureAd = event.url.includes('azureAd') ? true : false;
      }
    });
  }

  public logout() {
    this.removeContent();
    if (this.azureAdService.isUserAuthenticated()) {
      if (this.selectedApplication !== undefined) {
        this.azureAdService.selectedApplication = this.selectedApplication.appName;
      }
      this.targetedUrlIsAzureAd = true;
      this.store.dispatch(new Logout());
    } else {
      this.storage.clear();
      this.loginService.redirectToAppLauncher();
    }
  }

  public ngAfterViewInit() {
    this.progressBarService.showHideProgressBar(true, this.progressBarId);
  }

  public ngOnDestroy() {
    if (this.appNotificationSubscription !== undefined) { // tslint:disable-line: strict-type-predicates
      this.appRequestSubscription.unsubscribe();
    }

    if (this.appRequestSubscription !== undefined) { // tslint:disable-line: strict-type-predicates
      this.appRequestSubscription.unsubscribe();
    }
  }

  public async ngOnInit() {
    this.targetedUrlIsAzureAd = false;
    this.defaultApp = this.storage.getItem(Constants.app);
    const intialSelectedApp = this.application.find((app) => app.appName === this.defaultApp);

    if (this.storage.getItem('sessionStatus') !== 'true') {
      this.http.get('SessionStart', {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }).subscribe();

      // set session status
      this.storage.setItem('sessionStatus', 'true');
      const delay = 100;
      SharedFunctions.sleep(delay);
    }

    if (intialSelectedApp !== undefined) {
      if (intialSelectedApp.authenticationMechanism.name === AuthenticationType.AzureAd) {
        if (this.azureAdService.isUserAuthenticated()) {
          if (this.storage.getItem(CoreConstants.isUserLoggedIn) === Constants.empty) {
            this.storage.setItem(CoreConstants.isUserLoggedIn, 'true');
            this.signalrService.startConnection();
          }
          this.azureAdService.getIdToken()
            .then((idToken) => {
              // tslint:disable-next-line: strict-type-predicates
              if (idToken !== undefined) {
                this.storage.setItem('app', Applications.CareQuality);
                let userAccessProfile = this.profileRoleHelper.getUserAccessProfile(idToken);
                // This code will be display login user email Id
                // To do: Need to update Azure AD code to get user display name
                this.userName = userAccessProfile.userName;
                if (userAccessProfile.appProfiles.length === 0) {
                  SharedFunctions.sleep(CoreConstants.sleepTimeMs);
                  userAccessProfile = this.profileRoleHelper.getUserAccessProfile(idToken);
                }
                if (!this.loginService.verifyEmail(userAccessProfile.userName)) {
                  this.logout();
                  return;
                }
                if (userAccessProfile.appProfiles.length === 0) {
                  this.showMessage(CsopAlertMessageType.Danger, CoreConstants.notAuthorizedMsg);
                } else {
                  // store userAccessProfile in store
                  this.store.dispatch(new StoreUserProfile(userAccessProfile))
                    .subscribe((dataProfile) => {
                      if (dataProfile !== undefined &&
                        dataProfile.auth !== undefined) {
                        this.userProfile = dataProfile.auth;
                        this.setSelectedApplication(Applications.CareQuality);

                        this.appNotificationSubscription = this.appNotification.subscribe(
                          (data: Event) => {
                            const coreEvent: CoreEvent = (data as CustomEvent).detail;
                            this.toastr.info(coreEvent.payload, 'App ID::' + coreEvent.elementId);
                          }
                        );

                        this.appRequestSubscription = this.appRequest.subscribe(
                          (data: Event) => {
                            const coreEvent: CoreEvent = (data as CustomEvent).detail;
                            // const msg = new CoreResponse(coreEvent.elementId, CsopEventType.Response, this.userProfile);
                            if (this.user$ !== undefined) {
                              this.user$.subscribe(user => {
                                const docEvent = new CustomEvent(CsopEventType[CsopEventType.Response] + '_' + coreEvent.elementId,
                                  { detail: user.token });
                                document.dispatchEvent(docEvent);
                              });
                            }
                          }
                        );
                      } else {
                        this.toastr.warning('You are not authorized to access any application.');
                      }
                    },
                      error => {
                        console.error(error);
                      });
                }
              }
            }, (error) => {
              this.toastr.error('Failed to load Id token.');
              console.error(error);
            });
        }
      } else {
        if (this.loginService.isUserAuthenticated) {
          this.userName = this.storage.getItem('fullName');

          if (this.storage.getItem(CoreConstants.isUserLoggedIn) === Constants.empty) {
            this.storage.setItem(CoreConstants.isUserLoggedIn, 'true');
            this.signalrService.startConnection();
          }
          this.userProfile.appProfiles = this.profileRoleHelper.getApplicationProfiles();
          this.setSelectedApplication(this.storage.getItem('app'));
        } else {
          this.toastr.warning('User is not authorized to access any application.');
        }
      }
    }
  }
  /// ****************** Please don't remove below commented code *************************************//

  // public ngOnInit() {
  //  if (this.azureAdService.isUserAuthenticated()) {
  //    const applications = new Array<AppProfile>();
  //    let count = 0;

  //    // get user profile from azure
  //    this.azureAdService.getAzureUserProfile()
  //      .then((listAzureUserProfile) => {
  //         // tslint:disable-next-line: strict-type-predicates
  //        if (listAzureUserProfile !== undefined) {
  //          // filter out applcation as per user rights and filter userprofile for non CSOP application
  //          const userAccessProfile = this.profileRoleHelper.getUserAccessProfile(listAzureUserProfile);

  //          for (const app of userAccessProfile.appProfiles) {
  //            count++;

  //            // get Role name details from azure based on application
  //            this.azureAdService.getAppRoles(app)
  //              .then((appDetails) => {
  //                applications.push(appDetails);
  //                if (count === userAccessProfile.appProfiles.length) {
  //                  userAccessProfile.appProfiles = applications;

  //                  // store userAccessProfile in store
  //                  this.store.dispatch(new StoreUserProfile(userAccessProfile))
  //                    .subscribe((dataProfile) => {
  //                      if (dataProfile !== undefined &&
  //                        dataProfile.auth !== undefined) {
  //                        this.userProfile = dataProfile.auth;
  //                        this.setSelectedApplication();

  //                        this.appNotificationSubscription = this.appNotification.subscribe(
  //                          (data: Event) => {
  //                            const coreEvent: CoreEvent = (data as CustomEvent).detail;
  //                            this.toastr.info(coreEvent.payload, 'App ID::' + coreEvent.elementId);
  //                          }
  //                        );

  //                        this.appRequestSubscription = this.appRequest.subscribe(
  //                          (data: Event) => {
  //                            const coreEvent: CoreEvent = (data as CustomEvent).detail;
  //                            // const msg = new CoreResponse(coreEvent.elementId, CsopEventType.Response, this.userProfile);
  //                            if (this.user$ !== undefined) {
  //                              this.user$.subscribe(user => {
  //                                const docEvent = new CustomEvent(CsopEventType[CsopEventType.Response] + '_' + coreEvent.elementId,
  //                                  { detail: user.token });
  //                                document.dispatchEvent(docEvent);
  //                              });
  //                            }
  //                          }
  //                        );

  //                      } else {
  //                        this.toastr.warning('User has access no applications ');
  //                      }
  //                    },
  //                      error => {
  //                        // Log error
  //                        console.error(error);
  //                        // this.loading = false;
  //                      });
  //                }
  //              });
  //          }
  //        }
  //      });
  //  }
  // }
  /// ****************** Please don't remove above commented code *************************************//

  public async onAppChange(
    event: Event
  ) {
    const inputElement = (<HTMLSelectElement>event.target);
    const appLabel = inputElement.selectedOptions[0].label;

    const result = <boolean>await this.dialogService.openConfirmDialog(
      CoreConstants.dlgTitleConfirmation,
      'Please save your work before navigating to the ' + appLabel,
      CoreConstants.dlgBtnSaveContinue,
      CoreConstants.dlgBtnCancel
    )
      .catch(err => {
        console.error(err);
      });

    if (result === true) {

      const targetedApp = inputElement.selectedOptions[0].value;

      const targetedAppSettings = this.getAppSetting(targetedApp);
      if (targetedAppSettings !== undefined) {
        this.storage.setItem(Constants.app, targetedApp);
        if (targetedAppSettings.authenticationMechanism.name === AuthenticationType.AzureAd) {
          if (this.azureAdService.isUserAuthenticated()) {
            this.swappedApp = targetedApp;
            this.setSelectedApplication(targetedApp);
          } else {
            this.storage.setItem(CoreConstants.isUserLoggedIn, Constants.empty);
            this.router.navigate(['/azureAd'], { queryParams: { app: targetedApp } });
          }
        } else if (targetedAppSettings.authenticationMechanism.name === AuthenticationType.Shield) {
          this.isAuthorized(targetedAppSettings).then(response => {
            if (response === true) {
              this.storage.setItem(Constants.app, this.defaultApp);
              this.swappedApp = targetedApp;
              if (this.storage.getItem('app') === Applications.FoD) {
                this.sendSwapEvent();
              } else {
                this.setSelectedApplication(targetedApp);
              }
            } else {
              this.storage.setItem(CoreConstants.isUserLoggedIn, Constants.empty);
              this.router.navigate(['/login'], { queryParams: { app: targetedApp } });
            }
          }).catch(error => {
            console.log(error);
            this.showToasterMessage(AlertMessageType.Danger, 'You are not authorized to access ' + appLabel + '.');
          });
        }
      }
    } else {
      inputElement.value = this.storage.getItem('app');
    }
  }

  @HostListener('window:beforeunload')
  public windowCloseEvent() {
    /* Note: Below line is added as system should not logout user while system is redirect user
      for Azure AD authentication.*/
    if (!this.targetedUrlIsAzureAd) {
      this.logout();
    }
  }

  private getAppSetting(
    appName: string
  ): IAppSettings | undefined {
    const appSetting = this.application.find((app: IAppSettings) => app.appName === appName);
    return appSetting !== undefined ? appSetting : undefined;
  }

  private async getShieldAuthenticated(
    appSettings: IAppSettings
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      let token = null; // tslint:disable-line: no-null-keyword

      const anotherShieldApp = this.application.find((app) =>
        app.authenticationMechanism.name === AuthenticationType.Shield
        && app.appName !== appSettings.appName);

      if (anotherShieldApp !== undefined) {
        const tokenKey = anotherShieldApp.appName + '-' + anotherShieldApp.authenticationMechanism.setting.tokenType;
        token = this.storage.getItem(tokenKey);
        if (token !== null && token !== undefined && token !== '') { // tslint:disable-line:strict-type-predicates
          resolve(true);
        } else {
          resolve(await this.loginService.getRefreshTokenPromise(appSettings.appName, anotherShieldApp.appName));
        }
      } else {
        resolve(false);
      }
    });
  }

  private handleMessage(
    msg: Event
  ): void {
    this.toastr.info('shell received message: ', (<CustomEvent>msg).detail);
  }

  private handleSessionTimeout(): void {
    if (this.activeToast === undefined ||
      (
        // tslint:disable-next-line: strict-type-predicates
        this.activeToast !== undefined &&
        this.activeToast.message !== 'Session Timed out!'
      )) {
      this.activeToast = this.toastr.error('Session Timed out!');

      const delay = 2000;

      setTimeout(() => {
        this.logout();
      }, delay);
    }
  }

  private handleSwapping(swapEvent: CsopSwapEventType): void {
    switch (swapEvent) {
      case CsopSwapEventType.Continue:
        this.setSelectedApplication(this.swappedApp);
        break;
      case CsopSwapEventType.Cancel:
        this.defaultApp = this.storage.getItem(Constants.app);
    }
  }

  private async isAuthorized(
    appSettings: IAppSettings
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      let token = null; // tslint:disable-line: no-null-keyword
      if (appSettings !== undefined) { // tslint:disable-line:strict-type-predicates
        token = this.storage.getItem(appSettings.appName + '-' + appSettings.authenticationMechanism.setting.tokenType);
        // tslint:disable-next-line:prefer-switch
        if (token === null || token === undefined || token === '') { // tslint:disable-line:strict-type-predicates
          await this.getShieldAuthenticated(appSettings)
            .catch(err => {
              console.error(err);
              resolve(false);
            })
            .then(
              res => {
                res === true ? resolve(true) : resolve(false);
              }
            );
        } else {
          resolve(true);
        }
      } else {
        resolve(false);
      }
    });
  }

  private loadApplication(
    app: AppProfile
  ) {
    let content = document.getElementById('content');

    // tslint:disable-next-line: strict-type-predicates
    if (content === null || content === undefined) {
      SharedFunctions.sleep(CoreConstants.sleepTimeMs);
      content = document.getElementById('content');
    }
    if (content !== null) {
      const script = document.createElement('script');
      script.src = app.appModulePath;

      content.appendChild(script);

      const element: HTMLElement = document.createElement(app.appName);
      content.appendChild(element);
      element.addEventListener('message', msg => this.handleMessage(msg));
      element.addEventListener('session timeout', () => this.handleSessionTimeout());

      content.addEventListener(CsopSwapEventType[CsopSwapEventType.Continue],
        () => this.handleSwapping(CsopSwapEventType.Continue));

      content.addEventListener(CsopSwapEventType[CsopSwapEventType.Cancel],
        () => this.handleSwapping(CsopSwapEventType.Cancel));

      element.setAttribute('clientid', app.appName);
      element.setAttribute('state', JSON.stringify(
        this.userProfile.appProfiles.find(t => t.appName === app.appName)));

      script.onerror = () => console.error(`error loading ${app.appName}`);
      this.stateSvc.registerClient(element);
    }
  }

  private removeContent() {
    const content = document.getElementById('content');
    if (content !== null) {
      while (content.firstChild !== null) {
        content.removeChild(content.firstChild);
      }
    }
  }

  private sendSwapEvent() {
    const swapEvent = new Event(CsopSwapEventType[CsopSwapEventType.Notify], { bubbles: false, cancelable: false });
    const app = this.storage.getItem('app');
    const appElement = document.getElementsByTagName(app)[0];
    appElement.dispatchEvent(swapEvent);
  }

  private setSelectedApplication(
    app: string
  ) {
    const listAppProfiles = this.userProfile.appProfiles;
    if (listAppProfiles.length > 0) {
      this.selectedApplication = listAppProfiles.find(X => X.appName.toLowerCase() === app.toLowerCase());
      // tslint:disable-next-line: strict-type-predicates
      if (this.selectedApplication !== undefined) {
        this.removeContent();
        this.defaultApp = this.selectedApplication.appName;
        this.loadApplication(this.selectedApplication);
        this.storage.setItem('app', this.selectedApplication.appName);

        // event that trigger on selected application dom generated. Will execute one time on appload only.
        const selectedAppDom = document.querySelector(this.selectedApplication.appName) as Element;
        const config = { childList: true };
        const observer = new MutationObserver(() => {
          this.progressBarService.showHideProgressBar(false, this.progressBarId);
          observer.disconnect();
        });
        observer.observe(selectedAppDom, config);

        // Route to default route of the application
        this.location.go(
          this.location.normalize(
            this.selectedApplication.defaultNavigationPath
          ));
      } else {
        this.router.navigate(['/login'], { queryParams: { app: app } });
        return;
      }
    }
  }

  private showMessage(
    type: CsopAlertMessageType,
    message: string
  ) {
    const alert: ICsopAlertMessage = {
      type: type,
      message: message,
      alertComponentId: this.alertComponentId
    };
    this.alertService.open(alert);
  }

  private showToasterMessage(
    type: AlertMessageType,
    message: string
  ) {
    if (this.activeToast === undefined ||
      (
        // tslint:disable-next-line: strict-type-predicates
        this.activeToast !== undefined &&
        (
          this.activeToast.message !== message ||
          this.activeToast.toastRef.isInactive()
        ))) {
      switch (type) {
        case AlertMessageType.Danger:
          this.activeToast = this.toastr.error(message);
          break;
        case AlertMessageType.Info:
          this.activeToast = this.toastr.info(message);
          break;
        case AlertMessageType.Warning:
          this.activeToast = this.toastr.warning(message);
          break;
        case AlertMessageType.Success:
          this.activeToast = this.toastr.success(message);
          break;
      }
    }
  }
  // public toggleSidebar(){
  //   this.toggle.emit();
  // }
}
// tslint:disable-line:max-file-line-count
