import * as i0 from "./core-app-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./core-app-container.component";
import * as i5 from "../../../projects/csop-lib/src/lib/services/alertservice/csop-alert.service";
import * as i6 from "../../../projects/csop-lib/src/lib/services/azureservices/azure-ad-service";
import * as i7 from "../../../projects/csop-lib/src/lib/services/configservice/configuration.service";
import * as i8 from "../../../projects/csop-lib/src/lib/services/dialogservices/csop-dialog.service";
import * as i9 from "../../../projects/csop-lib/src/lib/services/loginservices/login.service";
import * as i10 from "../core/helpers/profile-role.helper";
import * as i11 from "@angular/router";
import * as i12 from "../state.service";
import * as i13 from "@ngxs/store";
import * as i14 from "../core/services/storage.service";
import * as i15 from "../../../projects/csop-lib/src/lib/services/toastrservice/toastr.service";
import * as i16 from "../../../projects/csop-lib/src/lib/services/signalRservice/csop-signalr.service";
import * as i17 from "../../../projects/csop-lib/src/lib/services/progressbarservice/csop-progressbar.service";
import * as i18 from "@angular/common/http";
var styles_CoreAppContainerComponent = [i0.styles];
var RenderType_CoreAppContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CoreAppContainerComponent, data: {} });
export { RenderType_CoreAppContainerComponent as RenderType_CoreAppContainerComponent };
function View_CoreAppContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.appName; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.appName; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.appTitle; _ck(_v, 3, 0, currVal_2); }); }
export function View_CoreAppContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "app-grid-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "asg-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "navbar-brand"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Common Service Operational Portals "])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "select", [["id", "appChange"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.onAppChange($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(8, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(10, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CoreAppContainerComponent_1)), i1.ɵdid(12, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 6, "div", [["class", "navbar-logout"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Welcome "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [["class", "navbar-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, [" ", " "])), (_l()(), i1.ɵted(-1, null, [" \u00A0\u00A0\u00A0 "])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "btn btn-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Logout"])), (_l()(), i1.ɵeld(20, 0, null, null, 0, "div", [["class", "content"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "div", [["class", "content"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "div", [["class", "content"], ["id", "content"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.defaultApp; _ck(_v, 8, 0, currVal_7); var currVal_8 = _co.userProfile.appProfiles; _ck(_v, 12, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 10).ngClassValid; var currVal_5 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = _co.userName; _ck(_v, 16, 0, currVal_9); var currVal_10 = _co.alertComponentId; _ck(_v, 20, 0, currVal_10); var currVal_11 = _co.progressBarId; _ck(_v, 21, 0, currVal_11); }); }
export function View_CoreAppContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-app-container", [], null, [["window", "beforeunload"]], function (_v, en, $event) { var ad = true; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).windowCloseEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CoreAppContainerComponent_0, RenderType_CoreAppContainerComponent)), i1.ɵprd(512, null, i3.LocationStrategy, i3.PathLocationStrategy, [i3.PlatformLocation, [2, i3.APP_BASE_HREF]]), i1.ɵprd(512, null, i3.Location, i3.Location, [i3.LocationStrategy, i3.PlatformLocation]), i1.ɵdid(3, 4440064, null, 0, i4.CoreAppContainerComponent, [i5.CsopAlertService, i6.AzureAdService, i7.ConfigService, i8.CsopDialogService, i3.Location, i9.LoginService, i10.ProfileRoleHelper, i11.Router, i12.StateService, i13.Store, i14.StorageService, i15.ToasterService, i16.CsopSignalRService, i17.CsopProgressBarService, i18.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var CoreAppContainerComponentNgFactory = i1.ɵccf("app-app-container", i4.CoreAppContainerComponent, View_CoreAppContainerComponent_Host_0, {}, {}, []);
export { CoreAppContainerComponentNgFactory as CoreAppContainerComponentNgFactory };
