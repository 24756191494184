import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { OnDestroy } from '@angular/core';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { JwksValidationHandler } from 'angular-oauth2-oidc';
import * as jwt_decode from 'jwt-decode';
import { Subscription } from 'rxjs';
import { ProfileRoleHelper } from 'src/app/core/helpers/index';
import { AuthenticationType, AuthProviders, Constants, CsopSettings } from '../../../public-api';
import { ConfigService } from '../configservice/configuration.service';
import { CsopDialogService } from '../dialogservices/csop-dialog.service';
import { GenericDataService } from '../dataservice/generic-data.service.';
import * as i0 from "@angular/core";
import * as i1 from "@azure/msal-angular/dist/broadcast.service";
import * as i2 from "../configservice/configuration.service";
import * as i3 from "../dataservice/generic-data.service.";
import * as i4 from "../../../../../../src/app/core/helpers/profile-role.helper";
import * as i5 from "@azure/msal-angular/dist/msal.service";
import * as i6 from "@angular/common/http";
import * as i7 from "../dialogservices/csop-dialog.service";
var AzureAdService = (function () {
    function AzureAdService(broadcastService, configService, genericDataService, profileRoleHelper, msalService, http, csopService) {
        var _this = this;
        this.broadcastService = broadcastService;
        this.configService = configService;
        this.genericDataService = genericDataService;
        this.profileRoleHelper = profileRoleHelper;
        this.msalService = msalService;
        this.http = http;
        this.csopService = csopService;
        this.accessToken = '';
        this.selectedApplication = Constants.empty;
        this.authProviders = new AuthProviders();
        this.csopSettings = new CsopSettings();
        this.subscription = new Subscription();
        this.sendWarningMessage = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.csopService.openWarningDialog(Constants.sessionWarning, Constants.sessionWarningMessage, false, false)
                            .catch(function (err) {
                            if (err !== undefined &&
                                err !== false &&
                                err !== 1) {
                                console.log(err);
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        }); };
    }
    AzureAdService.prototype.getAccessToken = function (endpointUri) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var scopes;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.accessToken = '';
                scopes = this.msalService.getScopesForEndpoint(endpointUri);
                return [2, new Promise(function (resolve) {
                        _this.msalService.acquireTokenSilent(scopes)
                            .then(function (accessToken) {
                            _this.accessToken = accessToken;
                            resolve(true);
                        }).catch(function () {
                            if (localStorage.getItem('msal.idtoken') === null || localStorage.getItem('msal.idtoken') === Constants.empty) {
                                _this.msalService.logout();
                            }
                            else {
                                _this.msalService.acquireTokenRedirect(scopes);
                            }
                        });
                    })];
            });
        });
    };
    AzureAdService.prototype.getAppRoles = function (app) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2, new Promise(function (resolve, reject) {
                        var url = _this.authProviders.microsoftGraphApiEndpoint +
                            'beta/applications?$filter=appId eq \'' +
                            app.applicationId + '\'';
                        _this.http.get(url, _this.genericDataService.getHttpOptions(_this.accessToken))
                            .toPromise()
                            .catch(function (err) {
                            reject(new Error(err));
                        })
                            .then(function (data) {
                            var appProfile = _this.profileRoleHelper.setRolesForAppProfile(data, app);
                            resolve(appProfile);
                        });
                    })];
            });
        });
    };
    AzureAdService.prototype.getAzureUserProfile = function (userIdentifier) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2, new Promise(function (resolve, reject) {
                        _this.getAccessToken(_this.authProviders.microsoftGraphApiEndpoint)
                            .then(function (isReceivedToken) {
                            if (isReceivedToken) {
                                userIdentifier = _this.getUserOid();
                                _this.http.get(_this.authProviders.microsoftGraphApiEndpoint + 'beta/users/' + userIdentifier + '/appRoleAssignments', _this.genericDataService.getHttpOptions(_this.accessToken))
                                    .toPromise()
                                    .catch(function (err) {
                                    console.error(err);
                                })
                                    .then(function (data) {
                                    var listAzureUserProfile = _this.profileRoleHelper.getAzureUserProfiles(data);
                                    resolve(listAzureUserProfile);
                                });
                            }
                        }).catch(function (error) {
                            reject(new Error(error));
                        });
                    })];
            });
        });
    };
    AzureAdService.prototype.getIdToken = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2, new Promise(function (resolve, reject) {
                        _this.loadJwks().then(function (jwks) {
                            var tokenInfo = localStorage.getItem('msal.idtoken');
                            if (tokenInfo !== null) {
                                var token_1 = jwt_decode(tokenInfo);
                                var jwksValidationHandler = new JwksValidationHandler();
                                var validationParams = {
                                    accessToken: '',
                                    idToken: tokenInfo,
                                    jwks: jwks,
                                    idTokenClaims: token_1,
                                    idTokenHeader: _this.getHeaderOfIdToken(tokenInfo),
                                    loadKeys: function () { return new Promise(resolve); }
                                };
                                jwksValidationHandler.validateSignature(validationParams)
                                    .then(function () {
                                    resolve(token_1);
                                }, function (error) {
                                    reject(error);
                                });
                            }
                        });
                    })];
            });
        });
    };
    AzureAdService.prototype.isUserAuthenticated = function () {
        return this.msalService._oauthData.isAuthenticated;
    };
    AzureAdService.prototype.logout = function () {
        var element = document.getElementById('appChange');
        var app = element.options[element.selectedIndex].value;
        var selectedApp = this.selectedApplication !== Constants.empty ? this.selectedApplication : app;
        var authentication = this.configService.config.csopsettings.applications.find(function (x) { return x.appName === selectedApp; });
        localStorage.clear();
        if (authentication !== undefined) {
            if (authentication.authenticationMechanism.name === AuthenticationType.AzureAd) {
                this.msalService.logout();
            }
            else {
                this.removeContent();
                window.location.replace('/');
            }
        }
    };
    AzureAdService.prototype.ngOnDestroy = function () {
        this.broadcastService.getMSALSubject().next(1);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    AzureAdService.prototype.b64DecodeUnicode = function (headerBase64) {
        var base64 = headerBase64.replace(/\-/g, '+').replace(/\_/g, '/');
        return decodeURIComponent(atob(base64)
            .split('')
            .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
            .join(''));
    };
    AzureAdService.prototype.fetchAzureAdConfigration = function () {
        if (this.configService.config.csopsettings.applications.length === 0) {
            this.configService.loadUrl('../assets/csopsettings.json');
        }
        this.csopSettings = this.configService.config.csopsettings;
        this.authProviders = this.csopSettings.authProviders.filter(function (provider) { return provider.name === AuthenticationType.AzureAd; })[0];
    };
    AzureAdService.prototype.getHeaderOfIdToken = function (idToken) {
        var tokenParts = idToken.split('.');
        var headerBase64 = this.padBase64(tokenParts[0]);
        var headerJson = this.b64DecodeUnicode(headerBase64);
        return JSON.parse(headerJson);
    };
    AzureAdService.prototype.getUserOid = function () {
        this.user = this.msalService.getUser();
        var oid = this.user.idToken['oid'].toString();
        return oid;
    };
    AzureAdService.prototype.loadJwks = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.fetchAzureAdConfigration();
                return [2, new Promise(function (resolve, reject) {
                        var url = 'https://login.microsoftonline.com/' + _this.authProviders.tenantId + '/discovery/v2.0/keys';
                        _this.http.get(url)
                            .subscribe(function (jwks) {
                            resolve(jwks);
                        }, function (err) {
                            console.error(err);
                            reject(err);
                        });
                    })];
            });
        });
    };
    AzureAdService.prototype.padBase64 = function (base64data) {
        while (base64data.length % 4 !== 0) {
            base64data += '=';
        }
        return base64data;
    };
    AzureAdService.prototype.removeContent = function () {
        var content = document.getElementById('content');
        if (content !== null) {
            while (content.firstChild !== null) {
                content.removeChild(content.firstChild);
            }
        }
    };
    AzureAdService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AzureAdService_Factory() { return new AzureAdService(i0.ɵɵinject(i1.BroadcastService), i0.ɵɵinject(i2.ConfigService), i0.ɵɵinject(i3.GenericDataService), i0.ɵɵinject(i4.ProfileRoleHelper), i0.ɵɵinject(i5.MsalService), i0.ɵɵinject(i6.HttpClient), i0.ɵɵinject(i7.CsopDialogService)); }, token: AzureAdService, providedIn: "root" });
    return AzureAdService;
}());
export { AzureAdService };
